import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { MakeSure, TrustUs, Steps } from "../components/sections/page_5"
const FifthPage = () => (
  <Layout>
    <SEO title="Bitso" />
    <MakeSure />
    <TrustUs />
    <Steps />
  </Layout>
)

export default FifthPage
